import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./HomeBanner.css";

export default function Banner() {
  const sectionRef = useRef(null);
  const [message, setMessage] = useState("");
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [animate, setAnimate] = useState(false); // State for animation

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get("https://ap.bcsconfidence.net/api/banners");
        setBanners(response.data); // Set the fetched banners
      } catch (error) {
        console.error("Error fetching banners:", error);
        setMessage("Failed to fetch banners."); // Set error message
      } finally {
        setLoading(false); // Stop loading after fetch
        setAnimate(true); // Trigger animation after data is loaded
      }
    };

    fetchBanners(); // Call the fetch function

    const handleResize = () => {
      if (window.innerWidth > 768 && sectionRef.current) {
        sectionRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on component mount
    return () => window.removeEventListener("resize", handleResize); // Clean up listener
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading message while fetching data
  }

  return (
    <div className="responsive-img">
      {banners.length > 0 ? ( // Check if there are banners
        <div
          style={{
            backgroundImage: `url(https://ap.bcsconfidence.net/storage/${banners[0].image_path})`,
            fontFamily: "auto",
          }}
          className="header"
        >
          <div
            className={`p-on-desktop left ${animate ? "animate" : ""}`}
            ref={sectionRef}
          >
            <h1 style={{ color: "gold" }}>
              &ldquo;{banners[0].right_header}&rdquo;{" "}
            </h1>
            <h6>{banners[0].right_title}</h6>
            <h3>{"Lead By: " + banners[0].right_lead_by}</h3>
          </div>

          <div
            className={`p-on-desktop right ${animate ? "animate" : ""}`}
            ref={sectionRef}
          >
            <h1 style={{ color: "gold", fontWeight: "bold" }}>
              {banners[0].left_header}
            </h1>
            <h4>{banners[0].left_title}</h4>
            <h3>{banners[0].left_text}</h3>
          </div>
        </div>
      ) : (
        <div>No banners available.</div> // Handle case where no banners are fetched
      )}
      {message && <div className="error-message">{message}</div>}{" "}
      {/* Show error message if exists */}
    </div>
  );
}
