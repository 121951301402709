import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import Sidebar from "./Sidebar";

const AdminMenu = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  // Fetch data from the API when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://ap.bcsconfidence.net/api/menus");
      const result = await response.json();
      setData(result); // Assuming the API returns the data correctly
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setIsAdding(true);
    setEditingRow({ id: Date.now(), menuname: "", path: "" }); // Initialize 'path' too
    setShowModal(true);
  };

  const handleEdit = (row) => {
    setEditingRow({ ...row });
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`https://ap.bcsconfidence.net/api/menus/${id}`, {
        method: "DELETE",
      });
      setData(data.filter((row) => row.id !== id));
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleSave = async () => {
    try {
      if (isAdding) {
        // POST request for adding new menu item
        const response = await fetch("https://ap.bcsconfidence.net/api/menus", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            menuname: editingRow.menuname,
            path: editingRow.path, // Include path in the request
          }),
        });
        const newMenuItem = await response.json();
        setData([...data, newMenuItem]); // Add new item to data
      } else {
        // PUT request for editing existing menu item
        await fetch(`https://ap.bcsconfidence.net/api/menus/${editingRow.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            menuname: editingRow.menuname,
            path: editingRow.path, // Include path in the request
          }),
        });
        setData(
          data.map((row) => (row.id === editingRow.id ? editingRow : row))
        );
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setShowModal(false);
    }
  };

  const handleInputChange = (e) => {
    setEditingRow({ ...editingRow, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="">
        <Sidebar />
      </div>
      <div className="" style={{ width: "100%" }}>
        <div className="" style={{ margin: "10px", padding: "10px" }}>
          <Row>
            <Col>
              <h2 className="mb-4">Menu Item</h2>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <div className="" style={{ textAlign: "end" }}>
                    <Button
                      variant="success"
                      onClick={handleAdd}
                      className="mb-3"
                    >
                      Add New Entry
                    </Button>
                  </div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Menu Name</th>
                        <th>Path</th> {/* Add Path column */}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row) => (
                        <tr key={row.id}>
                          <td>{row.id}</td>
                          <td>{row.menuname}</td>
                          <td>{row.path}</td> {/* Display path */}
                          <td>
                            <Button
                              variant="primary"
                              size="sm"
                              className="me-2"
                              onClick={() => handleEdit(row)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDelete(row.id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </Col>
          </Row>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {isAdding ? "Add New Entry" : "Edit Entry"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Menu Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="menuname"
                    value={editingRow?.menuname || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Path</Form.Label> {/* Add path field */}
                  <Form.Control
                    type="text"
                    name="path"
                    value={editingRow?.path || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                {isAdding ? "Add" : "Save Changes"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AdminMenu;
