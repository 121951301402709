
import axios from "axios";
import React, { useState } from "react";
import Courseplan from "../../Component/Courseplan";
import Courses from "../../Component/Courses";
import Footer from "../../Component/Footer/Footer";
import Banner from "../../Component/HomeBanner";
import NavBar from "../../Component/NavBar/NavBar";
import Information from "../../Component/PersonInformation/Information";
import SubjectList from "../../Component/SubjectList";
import WeAre from "../../Component/WeAre.jsx/WeAre";



export default function Home() {

const [message, setMessage] = useState("");
const [banners, setBanners] = useState([]);
  const fetchBanners = async () => {
    try {
      const response = await axios.get("https://ap.bcsconfidence.net/api/banners");
      setBanners(response.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
      setMessage("Failed to fetch banners.");
    }
  };
  return (
    <div>
      <NavBar />
      <Banner
        
      />
      <h3 className="text-center m-3 ">আমাদের কার্যক্রম সমূহ</h3>
      <WeAre weare={""} />
      <div
        className=""
        style={{ backgroundColor: "rgb(249 249 249)", marginTop: "50px" }}
      >
        <Information />
      </div>

      {/* <h3 className="text-center my-3 py-1"> Photo Gallery</h3>
      <div className="">
        <PhotoSection />
      </div> */}
      {/* <Courses/> */}
      <div
        className=""
        style={{ backgroundColor: "#1a161f", marginTop: "50px" }}
      >
        {/* <h3 className="text-center" style={{ padding: "50px", color: "white" }}>
          আমাদের কোর্স সমূহ
        </h3> */}
        {/* <Cores /> */}
      </div>
      <div className="">
        <Courseplan />
      </div>
      <div className="">
        <h3 className="text-center m-3 ">আমাদের বুক সমূহ</h3>
        <SubjectList weare={""} />
      </div>
      <Footer />
    </div>
  );
}
