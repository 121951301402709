import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";

const StudentAdmit = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [formData, setFormData] = useState({
    full_name: "",
    mobile_number: "",
    birth_date: "",
    email: "",
    course: "",
    branch: "",
    year: "",
    batch: "",
    image: "",
    father_name: "",
    gender: "",
    nationality: "",
    blood_group: "",
    present_address: "",
    permanent_address: "",
    admission_fee: "",
    dues: "",
    register_no: "",
    status: false,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const STATUS_PENDING = false;
  const STATUS_APPROVED = true;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "https://ap.bcsconfidence.net/api/user-registers"
        );
        setUsers(response.data);
      } catch (err) {
        setError("Failed to fetch users: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://ap.bcsconfidence.net/api/user-registers/${id}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
    } catch (err) {
      setError("Failed to delete user: " + err.message);
    }
  };

  const handleEdit = (user) => {
    setEditUser(user);
    setFormData({
      ...user,
      image: user.image || "",
      father_name: user.father_name || "",
      gender: user.gender || "",
      nationality: user.nationality || "",
      blood_group: user.blood_group || "",
      present_address: user.present_address || "",
      permanent_address: user.permanent_address || "",
      admission_fee: user.admission_fee || "",
      dues: user.dues || "",
      register_no: user.register_no, // Do not allow changing this on edit
    });
    window.$("#editUserModal").modal("show");
  };

  const handleUpdate = async () => {
    try {
      await axios.put(
        `https://ap.bcsconfidence.net/api/user-registers/${editUser.id}`,
        formData
      );
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === editUser.id ? { ...user, ...formData } : user
        )
      );
      resetForm();
      window.$("#editUserModal").modal("hide");
    } catch (err) {
      setError("Failed to update user: " + err.message);
    }
  };

  const resetForm = () => {
    setFormData({
      full_name: "",
      mobile_number: "",
      birth_date: "",
      email: "",
      course: "",
      branch: "",
      year: "",
      batch: "",
      image: "",
      father_name: "",
      gender: "",
      nationality: "",
      blood_group: "",
      present_address: "",
      permanent_address: "",
      admission_fee: "",
      dues: "",
      register_no: "", // Clear this on reset
      status: STATUS_PENDING,
    });
    setEditUser(null);
  };

  const toggleStatus = async (userId) => {
    const updatedUser = users.find((user) => user.id === userId);
    const newStatus =
      updatedUser.status === STATUS_PENDING ? STATUS_APPROVED : STATUS_PENDING;

    try {
      await axios.put(`https://ap.bcsconfidence.net/api/user-registers/${userId}`, {
        ...updatedUser,
        status: newStatus,
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, status: newStatus } : user
        )
      );
    } catch (err) {
      setError("Failed to update status: " + err.message);
    }
  };

 const generateRegisterNo = (course, branch, year, batch, index) => {
   // Extract initials and last two digits of the year
   const courseInitial = course.charAt(0).toUpperCase();
   const branchInitial = branch.charAt(0).toUpperCase();
   const yearLastTwo = year.slice(-2); // Get the last two digits of the year
   const batchNumber = batch; // Assuming batch is provided as a string or number

   // Generate the register number
   return `${courseInitial}${branchInitial}${yearLastTwo}${batchNumber}${index}`;
 };

 const handleBatchYearChange = () => {
   // When the year or batch changes, generate a new register number
   if (formData.year && formData.batch && formData.course && formData.branch) {
     const index = users.length + 1; // Calculate based on the current max index in your data
     const newRegisterNo = generateRegisterNo(
       formData.course,
       formData.branch,
       formData.year,
       formData.batch,
       index
     );
     setFormData((prev) => ({ ...prev, register_no: newRegisterNo }));
   }
 };

  useEffect(() => {
    handleBatchYearChange();
  }, [formData.year, formData.batch]);

  const printStudentInfo = (userId) => {
    const student = users.find((user) => user.id === userId);
    if (!student) return;
    const logoUrl =
      "https://bcsconfidence.net/static/media/bcslogo.f9e9f3145585070c0f43.png";
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <title>${student.full_name}'s Information</title>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        <style>
          body { margin: 20px; }
          h1 { text-align: center; }
          img { max-width: 200px; display: block; margin: 0 auto; }
          .logo { max-width: 150px; display: block; margin: 0 auto; }
        </style>
      </head>
      <body>
        <img class="logo" src="${logoUrl}" alt="Company Logo" />
        <h1>${student.full_name}'s Information</h1>
        <img src="${student.image}" alt="${student.full_name}" />
        <table class="table table-bordered container">
          <tr><th>Field</th><th>Value</th></tr>
          <tr><td>Full Name</td><td>${student.full_name}</td></tr>
          <tr><td>Mobile Number</td><td>${student.mobile_number}</td></tr>
          <tr><td>Birth Date</td><td>${student.birth_date}</td></tr>
          <tr><td>Email</td><td>${student.email}</td></tr>
          <tr><td>Course</td><td>${student.course}</td></tr>
          <tr><td>Branch</td><td>${student.branch}</td></tr>
          <tr><td>Year</td><td>${student.year}</td></tr>
          <tr><td>Batch</td><td>${student.batch}</td></tr>
          <tr><td>Father's Name</td><td>${student.father_name}</td></tr>
          <tr><td>Nationality</td><td>${student.nationality}</td></tr>
          <tr><td>Admission Fee</td><td>${student.admission_fee}</td></tr>
          <tr><td>Dues</td><td>${student.dues}</td></tr>
          <tr><td>Registration No</td><td>${student.register_no}</td></tr>
          <tr><td>Status</td><td>${
            student.status ? "Approved" : "Pending"
          }</td></tr>
        </table>
        <script>
          window.print();
          window.onafterprint = function() { window.close(); }
        </script>
      </body>
    </html>
    `);
    printWindow.document.close();
  };

  const filteredUsers = users.filter((user) =>
    user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const navigate = useNavigate();

  const handleAddUserClick = () => {
    resetForm(); // Call the resetForm function
    navigate("/add-new-student"); // Navigate to the Add New Student page
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div style={{ display: "flex", backgroundColor: "#f0f4f8" }}>
      <Sidebar />
      <div className="container">
        <h1>Admission Student List</h1>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button className="btn btn-primary mb-3" onClick={handleAddUserClick}>
            Add Student
          </button>

          {/* Search input field */}
          <input
            type="text"
            className="form-control"
            style={{ width: "300px", marginBottom: "1rem" }}
            placeholder="Search by full name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
          />
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Full Name</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Register no</th>
              <th>Nationality</th>
              <th>Addmission Fee</th>
              <th>Dues</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.full_name}</td>
                <td>{user.mobile_number}</td>
                <td>{user.email}</td>
                <td>{user.register_no}</td>
                <td>{user.nationality}</td>
                <td>{user.admission_fee}</td>
                <td>{user.dues}</td>
                <td>
                  <button
                    className="btn btn-info me-2"
                    onClick={() => handleEdit(user)}
                    data-bs-toggle="modal"
                    data-bs-target="#editUserModal"
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger me-2"
                    onClick={() => handleDelete(user.id)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => printStudentInfo(user.id)}
                  >
                    Print
                  </button>
                  <button
                    className={`btn ${
                      user.status ? "btn-warning" : "btn-success"
                    } ms-2`}
                    onClick={() => toggleStatus(user.id)}
                  >
                    {user.status ? "Pending" : "Approved"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal for Edit User */}
        <div
          className="modal fade"
          id="editUserModal"
          tabIndex="-1"
          aria-labelledby="editUserModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editUserModalLabel">
                  {editUser ? "Edit User" : "Add User"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  {Object.entries(formData).map(([key, value]) => (
                    <div className="mb-3" key={key}>
                      <label htmlFor={key} className="form-label">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </label>
                      <input
                        type={key === "birth_date" ? "date" : "text"}
                        className="form-control"
                        id={key}
                        value={value}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            [key]: e.target.value,
                          })
                        }
                      />
                    </div>
                  ))}

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={editUser ? handleUpdate : handleAddUserClick}
                    >
                      {editUser ? "Update User" : "Add User"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentAdmit;
