import React from 'react'
import Sidebar from './Sidebar'

const AdminCourse = () => {
  return (
    <div>
      <Sidebar/>

      
    </div>
  )
}

export default AdminCourse
