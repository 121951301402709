import React from 'react'

const index = () => {
  return (
    <div className="container">
      <h3 className="text-center" style={{ padding: "50px", color: "black" }}>
        প্রতিষ্ঠাতার বিস্তারিত তথ্য
      </h3>
      <p>
        সদ্য গ্রাজুয়েট ছাত্র-ছাত্রীরা। সম্প্রতি ৪২তম স্পেশাল বিসিএস এ চুড়ান্ত
        ফলাফল প্রকাশিত হয়ে প্রাণের প্রতিষ্ঠান “বিসিএস কনফিডেন্স” থেকে ১০৫৭ জন
        সফলতার সাথে সিভিল সার্জন পদে নিয়ােগপ্রাপ্ত/গেজেটভুক্ত হয়েছেন। ৪০তম
        বিসিএস প্রার্থীদের ভাইভা চলমান এবং সম্প্রতি ৪১তম লিখিত পরীক্ষা সম্পন্ন
        হলাে। ৪৩তম প্রিলি. পরীক্ষা সম্পন্ন হয়ে লিখিত পরীক্ষার জন্য প্রস্তুতি
        গ্রহণ চলছে এরই মধ্যে ৪৪তম বিসিএস প্রিলি, সার্কুলার প্রকাশিত হয়েছে।
        বন্ধুরা, কাজে লাগানাের জন্য এই এক অনন্য সুযােগ!...
      </p>
    </div>
  );
}

export default index
