import MenuIcon from "@mui/icons-material/Menu";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
  Toolbar,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/image/bcslogo.png";

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    mobile_number: "",
    birth_date: "",
    email: "emon.tkgl@gmail.com",
    course: "",
    branch: "",
    year: "",
    batch: "",
    image: null,
    father_name: "",
    gender: "",
    nationality: "",
    blood_group: "",
    present_address: "",
    permanent_address: "",
    admission_fee: "",
    dues: "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("https://ap.bcsconfidence.net/api/menus");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();

      if (Array.isArray(result)) {
        const manualMenuItems = [
          {
            menuname: "Online Exam",
            path: "https://bcsconfidence.prostuti.net/",
          },
          {
            menuname: "Register",
            modal: true,
          },
        ];
        setPages([...result, ...manualMenuItems]);
      } else {
        throw new Error("API response is not an array");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormData({
      full_name: "",
      mobile_number: "",
      birth_date: "",
      email: "",
      course: "",
      branch: "",
      year: "",
      batch: "",
      image: null,
      father_name: "",
      gender: "",
      nationality: "",
      blood_group: "",
      present_address: "",
      permanent_address: "",
      admission_fee: "",
      dues: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://ap.bcsconfidence.net/api/user-registers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Registration failed");
      }

      const result = await response.json();
      console.log("Registration successful:", result);

      setSnackbar({
        open: true,
        message: "Registration successful!",
        severity: "success",
      });
      handleCloseModal();
    } catch (error) {
      console.error("Registration error:", error);
      setSnackbar({
        open: true,
        message: "Registration failed. Please try again.",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  const handleImageUpload = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      image: event.target.files[0],
    }));
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  display: { xs: "flex", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <img style={{ height: "80px" }} src={logo} alt="logo" />
              </Typography>
            </Box>

            {/* Mobile Menu */}
            <Box
              sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
            >
              <IconButton
                size="large"
                aria-label="open navigation menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ ml: "auto" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                {loading ? (
                  <MenuItem>
                    <Typography>Loading...</Typography>
                  </MenuItem>
                ) : error ? (
                  <MenuItem>
                    <Typography>Error: {error}</Typography>
                  </MenuItem>
                ) : (
                  pages.map((page, index) => (
                    <MenuItem key={index} onClick={handleCloseNavMenu}>
                      <Typography sx={{ textAlign: "center" }}>
                        {page.modal ? (
                          <Button onClick={handleOpenModal}>
                            {page.menuname}
                          </Button>
                        ) : (
                          <Link
                            to={page.path}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            {page.menuname}
                          </Link>
                        )}
                      </Typography>
                    </MenuItem>
                  ))
                )}
              </Menu>
            </Box>

            {/* Desktop Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {loading ? (
                <Typography sx={{ my: 2, color: "white" }}>
                  Loading...
                </Typography>
              ) : error ? (
                <Typography sx={{ my: 2, color: "white" }}>
                  Error: {error}
                </Typography>
              ) : (
                pages.map((page, index) =>
                  page.modal ? (
                    <Button
                      key={index}
                      onClick={handleOpenModal}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page.menuname}
                    </Button>
                  ) : (
                    <Link
                      key={index}
                      to={page.path}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Button sx={{ my: 2, color: "white", display: "block" }}>
                        {page.menuname}
                      </Button>
                    </Link>
                  )
                )
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Modal Component for Register */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="register-modal"
        aria-describedby="register-form"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: 600 },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" component="h2" id="register-modal">
            Register
          </Typography>

          <form onSubmit={handleRegister}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="mobile_number"
                  value={formData.mobile_number}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  required
                  type="tel"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Birth Date"
                  name="birth_date"
                  value={formData.birth_date}
                  onChange={handleInputChange}
                  type="date"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  required
                  type="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Course"
                  name="course"
                  value={formData.course}
                  onChange={handleInputChange}
                  select
                  margin="normal"
                  variant="outlined"
                  required
                  SelectProps={{ native: true }}
                >
                  <option value=""></option>
                  <option value="Preliminary">Preliminary</option>
                  <option value="Written">Written</option>
                  <option value="Viva">Viva</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Branch"
                  name="branch"
                  value={formData.branch}
                  onChange={handleInputChange}
                  select
                  margin="normal"
                  variant="outlined"
                  required
                  SelectProps={{ native: true }}
                >
                  <option value=""></option>
                  <option value="Fermeget">Fermeget</option>
                  <option value="Mirpur">Mirpur</option>
                  <option value="Uttra">Uttra</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Year"
                  name="year"
                  value={formData.year}
                  onChange={handleInputChange}
                  select
                  margin="normal"
                  variant="outlined"
                  required
                  SelectProps={{ native: true }}
                >
                  <option value=""></option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Batch"
                  name="batch"
                  value={formData.batch}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  required
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Father's Name"
                  name="father_name"
                  value={formData.father_name}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    label="Gender"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nationality"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Blood Group"
                  name="blood_group"
                  value={formData.blood_group}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Present Address"
                  name="present_address"
                  value={formData.present_address}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Permanent Address"
                  name="permanent_address"
                  value={formData.permanent_address}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Admission Fee"
                  name="admission_fee"
                  value={formData.admission_fee}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Dues"
                  name="dues"
                  value={formData.dues}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="raised" component="span">
                    Upload Image
                  </Button>
                </label>
                {formData.image && <span>{formData.image.name}</span>}
              </Grid>
            </Grid> */}
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Register
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Navbar;
