import React from 'react'
import Sidebar from './Sidebar'

const AdminVedio = () => {
  return (
    <div>
      <Sidebar/>
    </div>
  )
}

export default AdminVedio
