import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";

const Dashboard = () => {
  const [menuCount, setMenuCount] = useState(0);
  const [bannercount, setBanner] = useState(0);
  const [courseCount, setCourseCount] = useState(0);
  const [photoCount, setPhotoCount] = useState(0);
  const [videoCount, setVideoCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);

  useEffect(() => {
    fetchCounts();
  }, []);

  const fetchCounts = async () => {
    try {
      // Fetch menu count
      const menuResponse = await fetch("https://ap.bcsconfidence.net/api/menus");
      const menuData = await menuResponse.json();
      setMenuCount(menuData.length);

      //Fetch banner count 
      const bannerResponse = await fetch("https://ap.bcsconfidence.net/api/banners");
      const bannerData = await bannerResponse.json();
      setBanner(bannerData.length);

      // Fetch course count
      const courseResponse = await fetch("https://ap.bcsconfidence.net/api/courses");
      const courseData = await courseResponse.json();
      setCourseCount(courseData.length);

      // Fetch photo count
      const photoResponse = await fetch("https://ap.bcsconfidence.net/api/photos");
      const photoData = await photoResponse.json();
      setPhotoCount(photoData.length);

      // Fetch video count
      const videoResponse = await fetch("https://ap.bcsconfidence.net/api/videos");
      const videoData = await videoResponse.json();
      setVideoCount(videoData.length);

      // Fetch success story count
      const successResponse = await fetch(
        "https://ap.bcsconfidence.net/api/success-stories"
      );
      const successData = await successResponse.json();
      setSuccessCount(successData.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div style={{ display: "flex", backgroundColor: "#f0f4f8" }}>
      {/* Sidebar */}
      <div className="">
        <Sidebar />
      </div>

      {/* Main content */}
      <div style={{ flex: 1, padding: "20px" }}>
        <header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <h2 style={{ fontSize: "24px", fontWeight: "semibold" }}>
            Dashboard
          </h2>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <input
              type="text"
              placeholder="Search Here"
              style={{
                padding: "5px 10px",
                border: "1px solid #ddd",
                borderRadius: "4px",
              }}
            />
            <span>🔔</span>
            <span>📷</span>
          </div>
        </header>

        <div style={{ marginBottom: "30px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "15px",
            }}
          >
            {[
              { title: "Menu", count: menuCount },
              { title: "Banner", count: bannercount },
              { title: "Course", count: courseCount },
              { title: "Photo Gallery", count: photoCount },
              { title: "Video Gallery", count: videoCount },
              { title: "Success Story", count: successCount },
            ].map((item, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "white",
                  padding: "15px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                <h3 style={{ fontSize: "18px", fontWeight: "semibold" }}>
                  {item.title}
                </h3>
                <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {item.count}
                </p>
                <div
                  style={{
                    height: "50px",
                    backgroundColor: "#f0f4f8",
                    borderRadius: "4px",
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
