import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer id="footer" className="footer-section">
      <div className="container d-none d-md-block">
        <div className="row" style={{paddingTop:"25px"}}>
          {/* About Us Section */}
          <div className="col-lg-4 col-md-4">
            <h3 className="footer-title">About Us</h3>
            <p>
              BCS কনফিডেন্স ১৯৯১ সালে প্রতিষ্ঠিত চাকুরি প্রার্থীদের একটি সহায়ক
              প্রতিষ্ঠান।
            </p>
          </div>

          {/* Contact Section */}
          <div className="col-lg-3 col-md-3">
            <h3 className="footer-title">Contact</h3>
            <ul className="footer-list">
              <li>BCS Confidence</li>
              <li>২৬, ইন্দিরা রোড, ফার্মগেইট, ঢাকা</li>
              <li>info@bcsconfidence.net</li>
              <li>+8801911322261</li>
            </ul>
          </div>

          {/* Pages Section */}
          <div className="col-lg-2 col-md-2">
            <h3 className="footer-title">Pages</h3>
            <ul className="footer-list">
              <li>
                <a onClick={() => navigate("/rules")}>ভর্তির নিয়মাবলি</a>
              </li>
              <li>সকল প্রস্তুতি</li>
              <li>FAQ</li>
              <li>
                <a onClick={() => navigate("/special")}>আমাদের বৈশিষ্ট্য</a>
              </li>
            </ul>
          </div>

          {/* Logo & Social Media Section */}
          <div className="col-lg-3 col-md-3">
            <div className="footer-logo">
              <img
                className="img-fluid"
                src="https://bcsconfidence.prostuti.net/admin/media/logo/logo-63a43964406bc.png"
                alt="BCS Confidence Logo"
                style={{ width: "137px" }}
              />
            </div>
            <h5 className="mb-2">Follow Us On:</h5>
            <div className="footer-social">
              <a
                href="https://www.facebook.com/BCSconfidenceOfficial"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-square social-icon"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="row">
          <div className="footer-bottom p-3">
            <div className="d-flex justify-content-center align-items-center">
              <p>
                Copyright © BCS Confidence | Powered by
                <a
                  href="https://www.techknowgram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ marginBottom: "14px" }}
                    src={require("../../assets/image/navlogo.jpg")}
                    width="100"
                    height="35"
                    class="lazyloaded"
                    data-ll-status="loaded"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
