import React, { useState } from "react";
import logo from '../../assets/image/bcslogo.png';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation: check if fields are empty
    if (!email || !password) {
      alert("Please fill in both fields.");
      return;
    }

    // Set loading state to true when starting the request
    setLoading(true);

    const loginData = { email, password };

    fetch("https://ap.bcsconfidence.net/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          console.log("Login successful!", data);

          // Store token and redirect
          localStorage.setItem("token", data.token);
          window.location.href = "/dashboard"; // Replace '/' with your desired route
        } else {
          console.log("Login failed", data);
          alert("Invalid login credentials.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred during login. Please try again.");
      })
      .finally(() => {
        // Set loading state back to false after the request is done
        setLoading(false);
      });
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "currentColor",
    },
    form: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      width: "300px",
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      fontSize: "16px",
    },
    button: {
      width: "100%",
      padding: "10px",
      backgroundColor: "#4CAF50",
      color: "white",
      border: "none",
      borderRadius: "4px",
      fontSize: "16px",
      cursor: "pointer",
    },
    text: {
      textAlign: "center",
      marginTop: "10px",
      fontSize: "14px",
      color: "#666",
    },
    link: {
      color: "#4CAF50",
      textDecoration: "none",
    },
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div className="" style={{display:"flex",justifyContent:"center"}}>
          <img src={logo} style={{margin:"15px"}} alt="" />
        </div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={styles.input}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />
        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? "Logging in..." : "LOGIN"}
        </button>
        <p style={styles.text}>
          Not registered?{" "}
          <a href="#" style={styles.link}>
            Create an account
          </a>
        </p>
      </form>
    </div>
  );
};

export default Login;
