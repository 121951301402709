import React from 'react'
import Sidebar from './Sidebar'

const AdminPhoto = () => {
  return (
    <div>
      <Sidebar/>
    </div>
  )
}

export default AdminPhoto
