import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [openMenu, setOpenMenu] = useState({ student: false, teacher: false });

  const navItems = [
    { name: "Dashboard", path: "/dashboard" },
    {
      name: "Website",
      submenu: [
        { name: "Menu", path: "/admin-menu" },
        { name: "Banner", path: "/admin-banner" },
        { name: "Course", path: "/admin-course" },
        { name: "Photo", path: "/admin-photo" },
        { name: "Video", path: "/admin-video" },
        { name: "Success Story", path: "/admin-success-story" },
      ],
    },
    {
      name: "Student",
      submenu: [
        { name: "View Students", path: "/admin-students" },
        { name: "Add Student", path: "/admin-add-student" },
      ],
    },
    {
      name: "Teacher",
      submenu: [
        { name: "View Teachers", path: "/admin-teachers" },
        { name: "Add Teacher", path: "/admin-add-teacher" },
      ],
    },
  ];

  const toggleMenu = useCallback((menu) => {
    setOpenMenu((prev) => ({ ...prev, [menu]: !prev[menu] }));
  }, []);

  return (
    <div>
      <div
        style={{
          width: "250px",
          backgroundColor: "#3366ff",
          color: "white",
          padding: "20px",
          height: "100vh",
        }}
      >
        <h1
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "30px" }}
        >
          Admin
        </h1>
        <nav>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {navItems.map((item, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                {item.submenu ? (
                  <>
                    <span
                      onClick={() => toggleMenu(item.name.toLowerCase())}
                      style={{
                        cursor: "pointer",
                        color: "white",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {item.name}
                    </span>
                    {openMenu[item.name.toLowerCase()] && (
                      <ul
                        style={{ listStyleType: "none", paddingLeft: "20px" }}
                      >
                        {item.submenu.map((subItem, subIndex) => (
                          <li key={subIndex} style={{ marginBottom: "10px" }}>
                            <Link
                              to={subItem.path}
                              style={{
                                color: "white",
                                textDecoration: "none",
                                padding: "5px 0",
                              }}
                            >
                              {subItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <Link
                    to={item.path}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {item.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
