import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"; // Import Bootstrap Modal
import Sidebar from "./Sidebar";

const AdminBanner = () => {
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [image, setImage] = useState(null);
  const [newBannerImage, setNewBannerImage] = useState(null);
  const [rightHeader, setRightHeader] = useState("");
  const [rightTitle, setRightTitle] = useState("");
  const [rightLeadBy, setRightLeadBy] = useState("");
  const [leftHeader, setLeftHeader] = useState("");
  const [leftTitle, setLeftTitle] = useState("");
  const [leftText, setLeftText] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal state for adding/editing banner

  const fetchBanners = async () => {
    try {
      const response = await axios.get("https://ap.bcsconfidence.net/api/banners");
      setBanners(response.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
      setMessage("Failed to fetch banners.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      try {
        await axios.delete(`https://ap.bcsconfidence.net/api/banners/${id}`);
        fetchBanners();
        setMessage("Banner deleted successfully!");
      } catch (error) {
        console.error("Error deleting banner:", error);
        setMessage("Failed to delete banner.");
      }
    }
  };

  const handleEdit = (banner) => {
    setSelectedBanner(banner);
    setImage(null); // Reset image input
    setRightHeader(banner.right_header);
    setRightTitle(banner.right_title);
    setRightLeadBy(banner.right_lead_by);
    setLeftHeader(banner.left_header);
    setLeftTitle(banner.left_title);
    setLeftText(banner.left_text);
    setShowModal(true); // Open modal for editing
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (image) {
      formData.append("image", image);
    }
    // Append additional fields to form data
    formData.append("right_header", rightHeader);
    formData.append("right_title", rightTitle);
    formData.append("right_lead_by", rightLeadBy);
    formData.append("left_header", leftHeader);
    formData.append("left_title", leftTitle);
    formData.append("left_text", leftText);

    try {
      // Update the banner with the new image and data
      await axios.put(
        `https://ap.bcsconfidence.net/api/banners/${selectedBanner.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchBanners();
      setShowModal(false); // Close modal
      setSelectedBanner(null);
      setMessage("Banner updated successfully!");
    } catch (error) {
      console.error("Error updating banner:", error);
      setMessage("Failed to update banner.");
    }
  };

  const handleAddBanner = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (newBannerImage) {
      formData.append("image", newBannerImage);
    }
    // Append additional fields for new banner
    formData.append("right_header", rightHeader);
    formData.append("right_title", rightTitle);
    formData.append("right_lead_by", rightLeadBy);
    formData.append("left_header", leftHeader);
    formData.append("left_title", leftTitle);
    formData.append("left_text", leftText);

    try {
      // Add the new banner
      await axios.post("https://ap.bcsconfidence.net/api/banners", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchBanners();
      setShowModal(false); // Close modal
      setNewBannerImage(null); // Reset the image input
      setMessage("Banner added successfully!");
    } catch (error) {
      console.error("Error adding banner:", error);
      setMessage("Failed to add banner.");
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <div style={{ marginLeft: "20px", width: "100%" }}>
        <h1>Banners</h1>
        {message && <div className="alert alert-info">{message}</div>}

        <button
          className="btn btn-success mb-4"
          onClick={() => setShowModal(true)}
        >
          Add New Banner
        </button>

        {/* Table to display banners */}
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>ID</th>
              <th>Banner</th>
              <th>Right Header</th>
              <th>Right Title</th>
              <th>Right Lead By</th>
              <th>Left Header</th>
              <th>Left Title</th>
              <th>Left Text</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {banners.map((banner) => (
              <tr key={banner.id}>
                <td>{banner.id}</td>
                <td>
                  <img
                    src={`https://ap.bcsconfidence.net/storage/${banner.image_path}`}
                    alt="Banner"
                    width="100"
                  />
                </td>
                <td>{banner.right_header}</td>
                <td>{banner.right_title}</td>
                <td>{banner.right_lead_by}</td>
                <td>{banner.left_header}</td>
                <td>{banner.left_title}</td>
                <td>{banner.left_text}</td>
                <td>
                  <button
                    onClick={() => handleEdit(banner)}
                    className="btn btn-primary btn-sm mx-1"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(banner.id)}
                    className="btn btn-danger btn-sm mx-1"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal for adding/editing banners */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedBanner ? "Edit Banner" : "Add New Banner"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={selectedBanner ? handleSaveEdit : handleAddBanner}>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={(e) =>
                  selectedBanner
                    ? setImage(e.target.files[0])
                    : setNewBannerImage(e.target.files[0])
                }
              />
              <input
                type="text"
                placeholder="Right Header"
                value={rightHeader}
                onChange={(e) => setRightHeader(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Right Title"
                value={rightTitle}
                onChange={(e) => setRightTitle(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Right Lead By"
                value={rightLeadBy}
                onChange={(e) => setRightLeadBy(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Left Header"
                value={leftHeader}
                onChange={(e) => setLeftHeader(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Left Title"
                value={leftTitle}
                onChange={(e) => setLeftTitle(e.target.value)}
                required
              />
              <textarea
                placeholder="Left Text"
                value={leftText}
                onChange={(e) => setLeftText(e.target.value)}
                required
              />
              <button type="submit" className="btn btn-primary mx-2">
                {selectedBanner ? "Save Changes" : "Upload Banner"}
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default AdminBanner;
