import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./Page/About Us/About";
import Branch from "./Page/Branch/Branch";
import Contact from "./Page/Contact/Contact";
import ExamPanel from "./Page/ExamPanel/ExamPanel";
import Home from "./Page/Home/Home";
import Rule from "./Page/Rule";
import Special from "./Page/Special";
import Course from "./Page/Courses/Course";
import TeacherDetails from './Page/TeacherDetails/index'
import Login from "./Page/Admin/Login";
import Dashboard from "./Page/Admin/Dashboard";
import AdminManu from "./Page/Admin/AdminManu";
import AdminCourse from "./Page/Admin/AdminCourse";
import AdminPhoto from "./Page/Admin/AdminPhoto";
import AdminVedio from "./Page/Admin/AdminVedio";
import AdminStorty from "./Page/Admin/AdminSuccessStory";
import AdminBanner from "./Page/Admin/AdminBanner";
import UserRegister from "./Page/UserRegister";
import StudentAdmit from "./Page/Admin/StudentAdmit";
import AddNewStudent from "./Page/Admin/AddNewStudent";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route index element={<Home />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/branches" element={<Branch />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/teacher-details" element={<TeacherDetails />} />
        <Route path="/examportal" element={<ExamPanel />} />
        <Route path="/rules" element={<Rule />} />
        <Route path="/special" element={<Special />} />
        <Route path="/course" element={<Course />} />
        <Route path="/user-register" element={<UserRegister />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin-menu" element={<AdminManu />} />
        <Route path="/admin-banner" element={<AdminBanner />} />
        <Route path="/admin-course" element={<AdminCourse />} />
        <Route path="/admin-photo" element={<AdminPhoto />} />
        <Route path="/admin-vedio" element={<AdminVedio />} />
        <Route path="/admin-Success-Story" element={<AdminStorty />} />
        <Route path="/admin-students" element={<StudentAdmit />} />
        <Route path="/add-new-student" element={<AddNewStudent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
